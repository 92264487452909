import styled, {css} from "styled-components";
import msg from "./../../assets/svg/email-icon.svg";
import sended_msg from "./../../assets/svg/sended_msg.svg";
import calendar from "./../../assets/svg/calendar-icon.svg";
import trash from "./../../assets/svg/trash-icon.svg";

import chevron from "./../../assets/svg/chevron-icon.svg";
import gotoIcon from "./../../assets/svg/go-to.svg";

import {FilterClient, ISinglePost} from "../../store/posts";
import React, {FC, useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {formatDate} from "../../utilites";
import {usePostsActions} from "../../store/posts/hooks";
import downloadCalendarEvent from "../../utilites/downloadCalendarEvent";
import {PostKeyword} from "../../components/PostKeyword";
import {IPostCardClient} from "../../store/clients";
import {Checkbox} from "../../components/Checkbox";
import {SquareCheckbox} from "../../components/SquareCheckbox";
import {link} from "node:fs";



interface IProps {
    onEmail: () => void;
    item: ISinglePost;
    onSelectClient: (client: FilterClient) => void;
}
const PostItem:FC<IProps> = ({item, onSelectClient, onEmail})=>{

    const {onMarkAsViewed, onMarkAsRead} = usePostsActions()
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const { onDeletePost, onDeletePostKeyword } = usePostsActions();
    const sortDate = useMemo(() => {
        if (item.date_for_sorting) {
            var d = new Date(item.date_for_sorting * 1000);
            return formatDate(d);
        }
        if (!item.date_for_sorting) return null;
    }, [item.date_for_sorting]);



    const isCommitteeSession = useMemo(() => {
        return item._sender === "committee_session"
    }, [item._sender])

    const handleOpenLinks = ()=>{
       item?.links?.forEach(link => window.open(link, "_blank"));
       if(item.link){
           window.open(item.link, "_blank");
       }
       if(item.pdf_link){
           window.open(item.pdf_link, "_blank");
       }
       if(item.source_link){
           window.open(item.source_link, "_blank");
       }
    }

    const tagLink = useMemo(() => {
        let tempLink = "";
        if (item.first_link) tempLink = item.first_link;
        if (item.url) tempLink = item.url;
        if (item.link_to_submission) tempLink = item.link_to_submission;
        if (item.link) tempLink = item.link;
        if (item.inner_link) tempLink = item.inner_link;
        if (item.front_link) tempLink = item.front_link;
        if (item.source_link_app_agenda) tempLink = item.source_link_app_agenda;
        if (item.committee_link) tempLink = item.committee_link;
        const label = item.tag ? item.tag : item.source_name;
        if (tempLink)
            return (
                <HoverLink target="_blank" href={tempLink}>
                    {label}
                </HoverLink>
            );
        return label;
    }, [
        item
    ]);

    const titleComposed = useMemo(() => {
        if(!item.title) return item.name
        if (item.tag !== "סל התרופות") return item.title;
        return `${item.title} - ${item.inner_link_name}`;
    }, [item]);

    const handleDeleteKeyword = useCallback((keywordId: number) => {
        onDeletePostKeyword(item._sender, item.id, keywordId)
    }, [item._sender, item.id])

    const handleCreateClendarEvent = useCallback(() => {
        downloadCalendarEvent(item.start_date, `${item.title || ""} ${item.title_sufix || ""}`, item.committee, item.location)
    }, [item])

    const committee = useMemo(() => {
        return item.committee || item.committee_name
    }, [item]);

    const hasMultipleItems = isCommitteeSession && item.title_sufix?.includes("במספר נושאים");
    return(
        <Container isViewed={!!item._viewed} onClick = {()=>{!item._viewed && onMarkAsViewed(item._sender, item.id)}}>
            <Header isOpen = {isOpen}>
                <Actions>
                    {isCommitteeSession && (
                        <SvgButton onClick = {handleCreateClendarEvent}>
                            <Icon src = {calendar}/>
                        </SvgButton>
                    )}
                    <SvgButton onClick={() => onDeletePost({ node: item._sender, postId: item.id })}>
                        <Icon src = {trash}/>
                    </SvgButton>
                </Actions>
                <RightIcons>
                    <DateContainer>
                        <StyledDate>{sortDate}</StyledDate>
                    </DateContainer>
                    <SvgButton onClick={() => onEmail()}>
                        <Icon src = {item.sended ? sended_msg : msg}/>
                    </SvgButton>
                    <SquareCheckbox setIsCheckedCreate={()=>onMarkAsRead(item._sender, item.id)} checked={item.readed}/>
                </RightIcons>
            </Header>
            <Content>
                <ContentHeader>
                    <TabContainer onClick = {()=>setIsOpen(prev => !prev)}>
                        <TitleContainer>
                            <Title>{titleComposed}</Title>
                            <CometeeContainer>
                                {isCommitteeSession && !hasMultipleItems && <StyledCommitteeName>{item.title_sufix}</StyledCommitteeName>}
                                {isCommitteeSession && hasMultipleItems && <StyledCommitteeName>{t("comette-multiple-items")}</StyledCommitteeName>}
                                {isCommitteeSession && hasMultipleItems && item?.cmt_session_items?.map((el)=>(
                                    <StyledCommitteeName>{el.name}</StyledCommitteeName>
                                ))}
                                {isCommitteeSession && !hasMultipleItems && <StyledCommitteeName>{item.start_date}</StyledCommitteeName>}
                            </CometeeContainer>
                            {!isOpen && (
                                <TitleGroup>
                                    <TitleLabel>{t("post-keywords")} - </TitleLabel>
                                    <TitleCustomerContent>{item.keywords?.map((k:any) => k.keyword).join(" ")}</TitleCustomerContent>
                                </TitleGroup>
                            )}
                            {!isOpen && (
                                <TitleGroup>
                                    <TitleCustomersLabel>{t("post-customer")} - </TitleCustomersLabel>
                                    <TitleCustomerContent>
                                        {item?.clients?.map((client:any, index:number)=>(
                                            <StyledCustomerItem onClick={(e) =>{
                                                e.preventDefault()
                                                e.stopPropagation();
                                                onSelectClient({ id: client.id, client: client.name })
                                            }} key = {index}>{index !== 0 && ","}{client.name}</StyledCustomerItem>
                                        ))}
                                    </TitleCustomerContent>
                                </TitleGroup>
                            )}
                            <TextFooter>
                                {tagLink && <TagLinkContainer onClick={handleOpenLinks}>
                                    <Icon src = {gotoIcon}/>
                                    <TagLink>{tagLink}</TagLink>
                                </TagLinkContainer>}
                                {item.cat && <StyledCat>{item.cat}</StyledCat>}
                            </TextFooter>
                        </TitleContainer>
                        <SvgButton>
                            <ChevronIcon src = {chevron} isOpen={isOpen}/>
                        </SvgButton>
                    </TabContainer>
                </ContentHeader>
                {isOpen && (
                    <TextContainer>
                        <Text>
                            {item.description}
                        </Text>
                    </TextContainer>
                )}
                {isOpen && item?.keywords?.length && (
                    <KeywordContainer>
                        {item.keywords.map(
                            (keyword: { keyword: string; id: number }, index: number) => (
                                <PostKeyword confirmDelMessage="Are you sure ?" onDelete={() => handleDeleteKeyword(keyword.id)} key={index}>{keyword.keyword}</PostKeyword>
                            )
                        )}
                    </KeywordContainer>
                )}
                {isOpen && (
                    <CustomersContainer>
                        {item.clients?.map((client: IPostCardClient, index: number) => (
                            <Client
                                onClick={() =>
                                    onSelectClient({ id: client.id, client: client.name })
                                }
                                key={index}
                            >
                                {client.name}
                            </Client>
                        ))}
                    </CustomersContainer>
                )}
            </Content>
        </Container>
    )
}

export default PostItem;


const Container = styled.div<{isViewed:boolean}>`
    width: 100%;
    padding: 15px;
  border: 1px solid #BCBCBC;
  background: #BFE0FF;
  
  ${props => props.isViewed && css`
    background: #fff;
  `}
`
const Header = styled.div<{isOpen:boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-bottom: 25px;
  ${props => props.isOpen && css`
    border-bottom: 1.5px solid #0058A9;
  `}
`
const Actions = styled.div`
    display: flex;
  gap: 30px;
  flex-direction: row-reverse;
  align-items: center;
`
const SvgButton = styled.div`
    all:unset;
    cursor: pointer;
`
const Icon = styled.img`
`
const DateContainer = styled.div`
    display: flex;
  gap: 30px;
  align-items: center;
`
const StyledDate = styled.div`
`

const Content = styled.div`
    margin-top: 20px;
`
const Title = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
`
const ContentHeader = styled.div`
`

const ChevronIcon = styled(Icon)<{isOpen:boolean}>`
    transition: .2s ease-in;
    ${props => props.isOpen && css`
      transform: rotate(-90deg);
    `}
`

const TabContainer = styled.div`
    display: flex;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const TitleContainer = styled.div`
    display: flex;
  flex-direction: column;
`
const TitleGroup = styled.div`
    display: flex;
  align-items: center;
  margin-top: 5px;
  &:first-child{
    margin-top: 20px;
  }
`
const TitleLabel = styled.div`
  color: var(--Black, #000);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
const TitleCustomerContent = styled.div`
  font-size: 12px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
`

const TitleCustomersLabel = styled(TitleLabel)`
    font-size: 16px;
`

const StyledCustomerItem = styled.div`
  color: #0062BC;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:hover{
    text-decoration: underline;
  }
`
const TextContainer = styled.div`
    margin-top: 5px;
  padding-left: 50px;
  border-bottom: 1.5px solid #0058A9;
  padding-bottom: 20px;
`

const Text = styled.div`
  color: #000;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
const TextFooter = styled.div`
    width: 100%;
  display: flex;

  align-items: center;
  gap: 40px;
  margin-top: 20px;
`
const HoverLink = styled.a`
  color: inherit;
  cursor: pointer;
  transition: opacity 200ms linear;
  &:hover {
    opacity: 0.65;
  }
`;

const TagLinkContainer = styled.div`
    display: flex;
  align-items: center;
  gap: 10px;
`
const TagLink = styled.div``

const StyledCat = styled.div`
  color: #838080;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const KeywordContainer = styled.div`
    display: flex;
  margin-top: 20px;
  padding-bottom: 64px;
  gap: 15px;
  border-bottom: 1.5px solid #0058A9;
  flex-wrap: wrap;
`

const CustomersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 20px;
  text-align: center;
  padding-bottom: 64px;
  gap: 15px;
  border-bottom: 1.5px solid #0058A9;
`
const Client = styled.div`
  color: #0058A9;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
  &:hover{
    text-decoration: underline;
  }
`
const StyledCommitteeName = styled.p`
  margin: 0 0 5px;
`

const CometeeContainer = styled.div`
    display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`

const RightIcons = styled.div`
    display: flex;  
  align-items: center;
  flex-direction: row-reverse;
  gap: 20px;
  
  & .checkbox{
    width: 20px;
    height: 20px;
  }
`

